export const ENV = {
  onboarding: 'https://onboarding.gigmobility2.com/apiV2',
  booking: 'https://booking.gigmobility2.com/apiV2',
  settings: 'https://settings.gigmobility2.com/apiV2',
  captain: 'https://captain.gigmobility2.com/apiV2',
  partner: 'https://partner.gigmobility2.com/apiV2',
  inventory: 'https://inventory.gigmobility2.com/apiV2',
  procurement: 'https://procurement.gigmobility2.com/apiV2',
  ambassador: 'https://ambassador.gigmobility2.com/apiV2',
  operations: 'https://operations.gigmobility2.com/apiV2',
};

// git update-index --skip-worktree src/env.js

// git update-index --no-skip-worktree src/env.js
